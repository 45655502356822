import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Typography,
  Badge,
  Divider,
} from '@mui/material';
import {
  Dashboard,
  Inventory,
  Home,
  Star,
  Landscape,
  Info,
  AccountCircle,
  EventNote,
  PeopleAlt,
  PhotoLibrary,
  Work,
  ContactMail,
  Logout,
  Menu,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import axios from 'axios';

const drawerWidth = 240;
const collapsedWidth = 60;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  justifyContent: 'space-between',
}));

const Sidebar = ({ open, onToggle }) => {
  const [careerUnreadCount, setCareerUnreadCount] = useState(0);
  const [connectUnreadCount, setConnectUnreadCount] = useState(0);

  useEffect(() => {
    const socket = io('https://banka1964.com/stats/'); // Connect to backend
  
    socket.on('update-unread-counts', ({ careerUnread, connectUnread }) => {
      console.log('Unread counts received:', { careerUnread, connectUnread }); // Debugging log
      setCareerUnreadCount(careerUnread);
      setConnectUnreadCount(connectUnread);
    });
  
    return () => {
      socket.disconnect(); // Clean up connection on unmount
    };
  }, []);

  // Function to mark all as read
  const markAllAsRead = (type) => {
    axios.post(`https://banka1964.com/stats/${type}/mark-all-read`)
      .then(() => {
        // Reset unread count for the respective type
        if (type === 'career') {
          setCareerUnreadCount(0);
        } else if (type === 'connect') {
          setConnectUnreadCount(0);
        }
      })
      .catch((err) => {
        console.error(`Error marking ${type} records as read:`, err);
      });
  };

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: open ? drawerWidth : collapsedWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <DrawerHeader>
        {open && (
          <Typography variant="h6" noWrap>
            My Admin Panel
          </Typography>
        )}
        <IconButton onClick={onToggle}>
          <Menu />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {/* Dashboard */}
        <ListItem button component={Link} to="/">
          <ListItemIcon><Dashboard /></ListItemIcon>
          {open && <ListItemText primary="Dashboard" />}
        </ListItem>
        <Divider />

        {/* Manage Product */}
        <ListItem button component={Link} to="/CMSPage">
          <ListItemIcon><Inventory /></ListItemIcon>
          {open && <ListItemText primary="Manage Product" />}
        </ListItem>
        <Divider />

        {/* Manage Home Banner */}
        <ListItem button component={Link} to="/table/banka_home_banner/Home%20Banner/landscape">
          <ListItemIcon><Home /></ListItemIcon>
          {open && <ListItemText primary="Manage Home Banner" />}
        </ListItem>
        <Divider />

        {/* Manage Popular Banner */}
        <ListItem button component={Link} to="/table/banka_popular_banner/Popular%20Banner/landscape">
          <ListItemIcon><Star /></ListItemIcon>
          {open && <ListItemText primary="Manage Popular Banner" />}
        </ListItem>
        <Divider />

        {/* Manage Valley Banner */}
        <ListItem button component={Link} to="/table/banka_valley_banner/Valley%20Banner/landscape">
          <ListItemIcon><Landscape /></ListItemIcon>
          {open && <ListItemText primary="Manage Valley Banner" />}
        </ListItem>
        <Divider />

        {/* Manage About Us */}
        <ListItem button component={Link} to="/table/banka_about_us/About Us/potrait">
          <ListItemIcon><Info /></ListItemIcon>
          {open && <ListItemText primary="Manage About Us" />}
        </ListItem>
        <Divider />

        {/* Manage Director */}
        <ListItem button component={Link} to="/table/banka_director/Manage Director/landscape">
          <ListItemIcon><AccountCircle /></ListItemIcon>
          {open && <ListItemText primary="Manage Director" />}
        </ListItem>
        <Divider />

        {/* Manage Event Page Text */}
        <ListItem button component={Link} to="/table/banka_event_data/Event Page Text/landscape">
          <ListItemIcon><EventNote /></ListItemIcon>
          {open && <ListItemText primary="Manage Event Page Text" />}
        </ListItem>
        <Divider />

        {/* Manage Event Participation */}
        <ListItem button component={Link} to="/table/banka_event_participation/Event Participation/landscape">
          <ListItemIcon><PeopleAlt /></ListItemIcon>
          {open && <ListItemText primary="Manage Event Participation" />}
        </ListItem>
        <Divider />

        {/* Manage Event Snaps */}
        <ListItem button component={Link} to="/table/banka_event_snapshots/Event Snaps/landscape">
          <ListItemIcon><PhotoLibrary /></ListItemIcon>
          {open && <ListItemText primary="Manage Event Snaps" />}
        </ListItem>
        <Divider />

        {/* Career with Unread Badge */}
        <ListItem
          button
          component={Link}
          to="/view/banka_career_applications/Career/landscape"
          onClick={() => markAllAsRead('career')}
        >
          <ListItemIcon>
            <Badge badgeContent={`${careerUnreadCount}`} color="secondary">
              <Work />
            </Badge>
          </ListItemIcon>
          {open && <ListItemText primary="Manage Career Data" />}
        </ListItem>
        <Divider />

        {/* Connect with Unread Badge */}
        <ListItem
          button
          component={Link}
          to="/view/banka_connect_requests/Connect/landscape"
          onClick={() => markAllAsRead('connect')}
        >
          <ListItemIcon>
            <Badge badgeContent={`${connectUnreadCount}`} color="secondary">
              <ContactMail />
            </Badge>
          </ListItemIcon>
          {open && <ListItemText primary="Manage Connect Data" />}
        </ListItem>
        <Divider />

        {/* Logout */}
        <ListItem button onClick={() => console.log('Logout clicked')}>
          <ListItemIcon><Logout /></ListItemIcon>
          {open && <ListItemText primary="Logout" />}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
